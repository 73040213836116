import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { values, throttle } from 'lodash';
import { placesAutocomplete } from '../googleMaps';

import styled from 'styled-components/macro';
import { theme, mixins, Ul } from '../style';
const { colors, spacing } = theme;

const AutocompleteContainer = styled(Ul)`
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 500px;
  padding: 0 ${spacing.lg};
  background-color: ${colors.white};
`;
const AutocompleteItem = styled.li`
  ${mixins.flexBetween};
  background-color: ${colors.white};
  padding: ${spacing.sm} 0;
  border-bottom: 1px solid ${colors.lightGrey};
  cursor: pointer;
  transition: ${theme.transition};
  line-height: 1.5;
  &:hover {
    color: ${colors.yellow};
  }
  &:last-of-type {
    border: none;
  }
`;
const Description = styled.div`
  display: flex;
`;
const Num = styled.span`
  display: inline-block;
  width: 30px;
  min-width: 30px;
`;
const Name = styled.span`
  display: inline-block;
`;
const Caret = styled.i`
  opacity: 0;
  ${AutocompleteItem}:hover & {
    opacity: 1;
  }
`;

class Autocomplete extends Component {
  static propTypes = {
    searchTerm: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
  };

  state = {
    queriedPlaces: [],
  };

  componentDidMount() {
    this.queryPlaces();
  }

  componentDidUpdate() {
    throttle(this.queryPlaces, 400).bind(this)();
  }

  queryPlaces() {
    const { searchTerm } = this.props;

    searchTerm &&
      placesAutocomplete.queryPlaces(searchTerm, predictions => {
        predictions && this.setState({ queriedPlaces: values(predictions) });
      });
  }

  render() {
    const { queriedPlaces } = this.state;
    const { handleClick } = this.props;

    return (
      <AutocompleteContainer>
        {queriedPlaces &&
          queriedPlaces.map((place, i) => {
            return (
              <AutocompleteItem
                key={i}
                place={place.place_id}
                onMouseDown={handleClick.bind(null, place)}>
                <Description>
                  <Num>{i + 1}</Num>
                  <Name>{place.description}</Name>
                </Description>
                <Caret className="fas fa-caret-right" />
              </AutocompleteItem>
            );
          })}
      </AutocompleteContainer>
    );
  }
}

export default Autocomplete;

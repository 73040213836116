import { createGlobalStyle } from 'styled-components/macro';
import theme from './theme';
const { colors, fontSizes, fonts } = theme;

const GlobalStyle = createGlobalStyle`
  /* Morion Regular */
  @font-face {
    font-family: Morion;
    src: url('/fonts/Morion-Regular.woff') format('woff'),
      url('/fonts/Morion-Regular.eot') format('eot');
    font-weight: 400;
    font-style: normal;
  }
  /* Morion Bold */
  @font-face {
    font-family: Morion;
    src: url('/fonts/Morion-Bold.woff') format('woff'), url('/fonts/Morion-Bold.eot') format('eot');
    font-weight: 700;
    font-style: normal;
  }
  /* Relative Book */
  @font-face {
    font-family: Relative;
    src: url('/fonts/Relative-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  /* Relative Bold */
  @font-face {
    font-family: Relative;
    src: url('/fonts/Relative-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  body {
    min-height: 100%;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: ${fonts.sans};
    font-size: ${fontSizes.base};
    color: ${colors.green};
    background-color: ${colors.white};
  }
`;

export default GlobalStyle;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { some } from 'lodash';
import { directions } from '../googleMaps';

import AddPlaceForm from '../components/AddPlaceForm';
import Autocomplete from '../components/Autocomplete';
import Place from './Place';

import styled from 'styled-components/macro';
import { theme, media, Main } from '../style';
const { colors, fontSizes, spacing } = theme;

const MainContainer = styled(Main)`
  max-width: ${theme.cardWidth};
  width: 100%;
  margin: 70px auto 0;
`;
const ListContainer = styled.section`
  margin-bottom: 0;
`;
const SortButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing.md} 0;
  border-top: 1px solid ${colors.lightGrey};
  position: sticky;
  top: 70px;
  z-index: 100;
  background: ${colors.white};
  outline: 1px solid ${colors.white};
  ${media.mobileL`
    padding: ${spacing.md};
  `};
`;
const SortButton = styled.div`
  color: ${colors.green};
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${fontSizes.xs};
  cursor: pointer;
  &:hover {
    color: ${colors.yellow};
  }
  &.active {
    color: ${colors.yellow};
  }
  i {
    margin-left: 5px;
  }
`;
const SortDistButton = styled(SortButton)`
  color: ${props => (props.sortType === 'distance' ? colors.yellow : colors.green)};
`;
const SortFavButton = styled(SortButton)`
  color: ${props => (props.sortType === 'favorites' ? colors.yellow : colors.green)};
`;

class PlaceList extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    places: PropTypes.array.isRequired,
    sortType: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
    setFirebasePlaces: PropTypes.func.isRequired,
    createFirebasePlace: PropTypes.func.isRequired,
    removeFirebasePlace: PropTypes.func.isRequired,
    favoriteFirebasePlace: PropTypes.func.isRequired,
    unfavoriteFirebasePlace: PropTypes.func.isRequired,
    setAllComments: PropTypes.func.isRequired,
    setAllItems: PropTypes.func.isRequired,
  };

  state = {
    inputActive: false,
    inputFocused: false,
    searchTerm: null,
    highlightedPlaceId: '',
  };

  componentDidMount() {
    const { sortType, sortOrder, setFirebasePlaces, setAllComments, setAllItems } = this.props;

    setFirebasePlaces(sortType, sortOrder);
    setAllComments();
    setAllItems();
  }

  highlightPlace = placeId => {
    this.setState({ highlightedPlaceId: placeId }, () => {
      this.highlightedPlace && this.highlightedPlace.getWrappedInstance().scrollTo();
    });

    setTimeout(() => this.setState({ highlightedPlaceId: '' }), 3000);
  };

  createPlace(place) {
    const placeId = place.place_id;
    const placeName = place.structured_formatting.main_text;

    directions.getPlaceDistance(placeId, res => {
      // Set place distance
      const placeDistance = res.routes.length > 0 ? res.routes[0].legs[0].duration.text : '';

      // Create place
      this.props.createFirebasePlace(placeId, placeName, placeDistance);

      this.highlightPlace(placeId);
    });
  }

  removePlace = id => {
    if (window.confirm('Do you really want to remove this place?')) {
      this.props.removeFirebasePlace(id);
    }
  };

  favoritePlace = placeId => {
    const currentUserGId = this.props.currentUser.id;
    this.props.favoriteFirebasePlace(placeId, currentUserGId);
  };

  unfavoritePlace = placeId => {
    const currentUserGId = this.props.currentUser.id;
    this.props.unfavoriteFirebasePlace(placeId, currentUserGId);
  };

  clearInput = evt => {
    this.setState({ inputActive: false, inputFocused: false, searchTerm: null });
    if (!evt) {
      return;
    }
    evt.target.value = '';
  };

  handleChange = evt => {
    const val = evt.target.value;
    val
      ? this.setState({ inputActive: true, searchTerm: val })
      : this.setState({ inputActive: false, searchTerm: null });
  };

  handleFocus = () => {
    this.setState({
      inputFocused: true,
      inputActive: true,
    });
  };

  handleClick = place => {
    const { places } = this.props;
    const isDup = some(places, { placeId: place.place_id });

    if (!isDup) {
      this.createPlace(place);
    } else {
      alert('This place has already been added!');
      setTimeout(() => this.highlightPlace(place.place_id), 100);
    }

    this.clearInput();
  };

  render() {
    const { inputActive, inputFocused, searchTerm, highlightedPlaceId } = this.state;
    const { currentUser, isSignedIn, places, sortType, sortOrder, setFirebasePlaces } = this.props;

    return (
      <MainContainer>
        <SortButtons>
          <SortDistButton
            onClick={setFirebasePlaces.bind(
              this,
              'distance',
              `${sortOrder === 'asc' ? 'desc' : 'asc'}`,
            )}
            sortType={sortType}
            sortOrder={sortOrder}>
            <span>Distance</span>
            <i className={`fas fa-caret-${sortOrder === 'asc' ? 'up' : 'down'}`} />
          </SortDistButton>
          <SortFavButton
            onClick={setFirebasePlaces.bind(
              this,
              'favorites',
              `${sortOrder === 'asc' ? 'desc' : 'asc'}`,
            )}
            sortType={sortType}
            sortOrder={sortOrder}>
            <span>Favorites</span>
            <i className={`fas fa-caret-${sortOrder === 'asc' ? 'up' : 'down'}`} />
          </SortFavButton>
        </SortButtons>

        <ListContainer>
          {places &&
            places.map(place => {
              const isHighlighted = highlightedPlaceId === place.placeId;
              return (
                <Place
                  key={place.index}
                  place={place}
                  removePlace={this.removePlace}
                  favoritePlace={this.favoritePlace}
                  unfavoritePlace={this.unfavoritePlace}
                  currentUser={currentUser}
                  isSignedIn={isSignedIn}
                  highlight={isHighlighted}
                  ref={el => isHighlighted && (this.highlightedPlace = el)}
                />
              );
            })}
        </ListContainer>

        {isSignedIn && (
          <AddPlaceForm
            handleFocus={this.handleFocus}
            handleChange={this.handleChange}
            clearInput={this.clearInput}
            inputActive={inputActive}
            inputFocused={inputFocused}
          />
        )}

        {searchTerm && <Autocomplete searchTerm={searchTerm} handleClick={this.handleClick} />}
      </MainContainer>
    );
  }
}

const mapStateToProps = ({ user, places }) => ({
  currentUser: user.currentUser,
  isSignedIn: user.isSignedIn,
  places: places.places,
  sortType: places.sortType,
  sortOrder: places.sortOrder,
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlaceList);

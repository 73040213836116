import { combineReducers } from 'redux';
import user from './userReducer';
import places from './placeReducer';
import comments from './commentReducer';
import items from './itemReducer';

export default combineReducers({
  user,
  places,
  comments,
  items,
});

import styled from 'styled-components/macro';
import theme from './theme';
const { fontSizes, spacing } = theme;

const Footer = styled.footer`
  padding: ${spacing.md};
  font-size: ${fontSizes.lg};
`;

export default Footer;

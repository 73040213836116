import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../actions/itemActions';
import { objectToList } from '../lib/helpers';

import Item from '../components/Item';
import SubmitButton from '../components/SubmitButton';

import styled from 'styled-components/macro';
import { theme, Input, Ol } from '../style';
const { colors, fontSizes, spacing } = theme;

const Items = styled.div`
  counter-reset: item;
  margin: ${spacing.md} 0;
  ol {
    padding: 0;
  }
`;
const NumberedList = styled(Ol)`
  position: relative;
`;
const AddItemForm = styled.form`
  display: flex;
  align-items: center;
  margin: ${spacing.md} 0;
  position: relative;
  color: ${colors.green};
  i {
    position: absolute;
    left: 17px;
    font-size: ${fontSizes.xs};
  }
`;
const AddItemInput = styled(Input)`
  background-color: transparent;
  border-color: transparent;
  border-bottom: 1px solid ${colors.green};
  transition: ${theme.transition};
  padding-left: ${spacing.xl};
  padding-right: ${spacing.lg};
  &::placeholder {
    text-transform: uppercase;
  }
`;

class ConnectedItemList extends Component {
  static propTypes = {
    placeIndex: PropTypes.string.isRequired,
    currentUser: PropTypes.object.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    createFirebaseItem: PropTypes.func.isRequired,
    removeFirebaseItem: PropTypes.func.isRequired,
    updateFirebaseItem: PropTypes.func.isRequired,
  };

  state = {
    inputActive: false,
  };

  createItem = evt => {
    evt.preventDefault();
    const { placeIndex, currentUser, createFirebaseItem } = this.props;
    const item = new FormData(evt.target).get('item');

    if (item) {
      createFirebaseItem(placeIndex, item, currentUser.id);
      this.clearInput(evt);
    }
  };

  removeItem = index => {
    const { placeIndex, removeFirebaseItem } = this.props;

    if (window.confirm('Do you really want to delete this item?')) {
      removeFirebaseItem(placeIndex, index);
    }
  };

  clearInput = evt => {
    this.setState({ inputActive: false });
    evt.target.reset();
  };

  handleChange = evt => {
    const val = evt.target.value;
    val ? this.setState({ inputActive: true }) : this.setState({ inputActive: false });
  };

  editItem = (evt, index) => {
    evt.preventDefault();
    const { placeIndex, updateFirebaseItem } = this.props;
    const formData = new FormData(evt.target);
    const newValue = formData.get('name');

    updateFirebaseItem(placeIndex, index, newValue);
  };

  render() {
    const { inputActive } = this.state;
    const { isSignedIn, items } = this.props;
    const placeItems = objectToList(items[0]);

    return (
      <Items>
        <NumberedList>
          {placeItems.map((item, index) => {
            return (
              item.item && (
                <Item
                  key={index}
                  isSignedIn={isSignedIn}
                  item={item.item}
                  itemIndex={item.index}
                  onDelete={this.removeItem}
                  editItem={this.editItem}
                />
              )
            );
          })}
        </NumberedList>
        {isSignedIn && (
          <AddItemForm onSubmit={this.createItem}>
            <i className="fas fa-plus" />
            <AddItemInput
              id="item"
              name="item"
              type="text"
              placeholder="Add an item"
              onChange={this.handleChange}
            />
            <SubmitButton inputActive={inputActive} />
          </AddItemForm>
        )}
      </Items>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
  isSignedIn: user.isSignedIn,
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedItemList);

import * as types from './types';
import { auth, db } from '../firebase';
import { objectToList } from '../lib/helpers';

const signIn = user => ({
  type: types.USER_SIGN_IN,
  payload: user,
});

const signOut = () => ({
  type: types.USER_SIGN_OUT,
  payload: {},
});

const createUser = googleUser => {
  db.doCreateUser(googleUser.uid, googleUser.displayName, googleUser.photoURL);
};

const getCurrentUser = googleUser => {
  return dispatch => {
    db.onGetCurrentUser(googleUser.uid, snapshot => {
      const userList = objectToList(snapshot.val());
      userList.length
        ? dispatch(signIn({ ...userList[0], id: googleUser.uid }))
        : createUser(googleUser, dispatch);
    });
  };
};

export const googleAuthChanged = () => {
  return dispatch => {
    auth.firebaseAuth.onAuthStateChanged(googleUser => {
      if (googleUser) {
        dispatch(getCurrentUser(googleUser));
      }
    });
  };
};

export const googleAuthSignIn = () => {
  return dispatch => {
    auth
      .googleAuth()
      .then(result => {
        dispatch(getCurrentUser(result.user));
      })
      .catch(error => {
        console.error(error);
      });
  };
};

export const googleAuthSignOut = () => {
  return dispatch => {
    if (window.confirm('Do you really want to sign out?')) {
      auth.authSignOut().then(dispatch(signOut()));
    }
  };
};

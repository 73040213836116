import * as types from './types';
import { db } from '../firebase';
import { objectToList } from '../lib/helpers';

const setItems = items => ({
  type: types.SET_ITEMS,
  payload: items,
});

const createItem = items => ({
  type: types.CREATE_ITEM,
  payload: items,
});

const removeItem = items => ({
  type: types.REMOVE_ITEM,
  payload: items,
});

const updateItem = items => ({
  type: types.UPDATE_ITEM,
  payload: items,
});

export const setAllItems = () => {
  return dispatch => {
    db.onGetItems(snapshot => {
      const items = objectToList(snapshot.val());
      dispatch(setItems(items));
    });
  };
};

export const setPlaceItems = placeIndex => {
  return dispatch => {
    db.onGetPlaceItems(placeIndex, snapshot => {
      const items = objectToList(snapshot.val());
      dispatch(setItems(items));
    });
  };
};

export const createFirebaseItem = (placeIndex, item, userId) => {
  return dispatch => {
    db.doCreatePlaceItem(placeIndex, item, userId).then(() => {
      db.onGetPlaceItems(placeIndex, snapshot => {
        const items = objectToList(snapshot.val());
        dispatch(createItem(items));
      });
    });
  };
};

export const removeFirebaseItem = (placeIndex, index) => {
  return dispatch => {
    db.doDeletePlaceItem(placeIndex, index).then(() => {
      db.onGetPlaceItems(placeIndex, snapshot => {
        const items = objectToList(snapshot.val());
        dispatch(removeItem(items));
      });
    });
  };
};

export const updateFirebaseItem = (placeIndex, index, newValue) => {
  return dispatch => {
    db.doUpdateItem(placeIndex, index, newValue, () => {
      db.onGetPlaceItems(placeIndex, snapshot => {
        const items = objectToList(snapshot.val());
        dispatch(updateItem(items));
      });
    });
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { objectToList } from '../lib/helpers';

import styled from 'styled-components/macro';
import { theme, mixins, media, Button } from '../style';
const { colors, fontSizes, spacing } = theme;

const ToggleWrapper = styled.div`
  ${mixins.flexBetween};
  position: relative;
  cursor: pointer;
  margin: ${spacing.md} 0;
  color: ${colors.green};
  border-color: ${colors.green};
  transition: ${theme.transition};

  &:hover {
    color: ${colors.yellow};
    border-color: ${colors.yellow};
  }
`;
const TopItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${colors.eggshell};
  line-height: 1.2;
`;
const Line = styled.span`
  min-width: 20px;
  height: 1px;
  background-color: ${colors.green};
  position: relative;
  top: 0;
  margin-left: 10px;
  flex-grow: 1;
  ${media.mobileM`min-width: 0;`};
`;
const ItemCount = styled.span`
  font-size: ${fontSizes.sm};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  padding: 0 10px;
  height: 100%;
`;
const ToggleButton = styled(Button)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${colors.white};
  transform: ${props => (props.listExpanded ? `rotate(180deg)` : `rotate(0deg)`)};
  transition: none;
  i {
    font-size: ${fontSizes.sm};
  }
`;

const ItemsToggle = ({ items, handleToggle, listExpanded }) => {
  const handleItemLang = itemCount => `${itemCount} item${itemCount > 1 ? 's' : ''}`;
  const placeItems = objectToList(items[0]);
  const firstItem = placeItems[0];

  return (
    <ToggleWrapper onClick={handleToggle}>
      <TopItem>{placeItems.length > 0 && `Get ${firstItem.item}`}</TopItem>
      <Line />
      <div>
        <ItemCount>{placeItems.length > 0 && handleItemLang(placeItems.length - 1)}</ItemCount>
        <ToggleButton listExpanded={listExpanded}>
          <i className="fas fa-caret-down" />
        </ToggleButton>
      </div>
    </ToggleWrapper>
  );
};

ItemsToggle.propTypes = {
  items: PropTypes.array.isRequired,
  handleToggle: PropTypes.func.isRequired,
  listExpanded: PropTypes.bool.isRequired,
};

export default ItemsToggle;

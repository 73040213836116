import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions';

import CommentList from './CommentList';
import ItemList from './ItemList';
import ItemsToggle from '../components/ItemsToggle';
import PlaceHeader from '../components/PlaceHeader';

import styled from 'styled-components/macro';
import { theme } from '../style';
const { colors, spacing } = theme;

const PlaceContainer = styled.div`
  background-color: ${colors.eggshell};
  padding: ${spacing.lg};
  margin: ${spacing.md} auto;
  position: relative;
  transition: ${theme.transition};
  outline: ${props => (props.highlight ? `1px solid ${colors.green}` : 0)};

  &:first-of-type {
    margin-top: ${spacing.sm};
  }
`;
const ItemListContainer = styled.div`
  max-height: ${props => (props.listExpanded ? `1000px` : `0`)};
  overflow: ${props => (props.listExpanded ? `visible` : `hidden`)};
  transition: ${theme.transition};
`;

class Place extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    highlight: PropTypes.bool.isRequired,
    place: PropTypes.object.isRequired,
    removePlace: PropTypes.func.isRequired,
    favoritePlace: PropTypes.func.isRequired,
    unfavoritePlace: PropTypes.func.isRequired,
    comments: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
  };

  state = {
    listExpanded: false,
  };

  handleToggle = () => this.setState({ listExpanded: !this.state.listExpanded });

  filterByPlaceIndex = (arr, i) => arr.filter(a => a.index === i);

  scrollTo = () => {
    this.place.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  render() {
    const { listExpanded } = this.state;
    const {
      currentUser,
      isSignedIn,
      highlight,
      place,
      removePlace,
      favoritePlace,
      unfavoritePlace,
      comments,
      items,
    } = this.props;

    const placeComments = this.filterByPlaceIndex(comments, place.index);
    const placeItems = this.filterByPlaceIndex(items, place.index);

    return (
      <PlaceContainer highlight={highlight} ref={el => (this.place = el)}>
        <PlaceHeader
          currentUser={currentUser}
          isSignedIn={isSignedIn}
          place={place}
          removePlace={removePlace}
          favoritePlace={favoritePlace}
          unfavoritePlace={unfavoritePlace}
        />

        {placeItems.length > 0 && (
          <ItemsToggle
            handleToggle={this.handleToggle}
            items={placeItems}
            listExpanded={placeItems < 1 || listExpanded}
          />
        )}

        <ItemListContainer listExpanded={placeItems < 1 || listExpanded}>
          <ItemList placeIndex={place.index} items={placeItems} />
          <CommentList placeIndex={place.index} comments={placeComments} />
        </ItemListContainer>
      </PlaceContainer>
    );
  }
}

const mapStateToProps = ({ comments, items }) => ({
  comments: comments.comments,
  items: items.items,
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true },
)(Place);

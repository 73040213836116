import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

import { theme, media, Button } from '../style';
const { colors } = theme;

const ButtonContainer = styled(Button)`
  opacity: 0;
  background-color: transparent;
  color: inherit;
  transition: ${theme.transition};
  ${media.mobileL`opacity: 1;`};
  &:hover {
    color: ${colors.red};
  }
`;

const DeleteButton = ({ onClick, itemIndex, item }) => (
  <ButtonContainer
    className="delete-btn"
    onClick={() => onClick(itemIndex)}
    title={item ? `Remove ${item}` : 'Remove'}>
    <i className="fas fa-trash" />
  </ButtonContainer>
);

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  itemIndex: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
};

export default DeleteButton;

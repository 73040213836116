import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';
import { theme, mixins, media, Img } from '../style';
const { colors, fontSizes, spacing } = theme;

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  height: 70px;
  overflow: hidden;
`;
const Nav = styled.nav`
  ${mixins.flexBetween};
  background-color: ${colors.white};
  max-width: ${theme.cardWidth};
  padding: ${spacing.sm} 0;
  color: ${colors.green};
  margin: 0 auto;
  outline: 1px solid ${colors.white};
  ${media.mobileL`
    padding: ${spacing.md};
  `};
`;
const NavText = styled.div`
  font-size: ${fontSizes.md};
  font-weight: 700;
`;
const AuthText = styled.div`
  padding: ${spacing.md} 10px;
  font-size: ${fontSizes.sm};
  transition: ${theme.transition};
`;
const AvatarContainer = styled.div`
  ${mixins.flexCenter};
  border-radius: 50%;
  background-color: ${colors.green};
  width: 30px;
  height: 30px;
  font-weight: 700;
  transition: ${theme.transition};
  i {
    color: ${colors.white};
  }
`;
const CurrentUserAvatar = styled(Img)`
  border-radius: 50%;
`;
const AuthButton = styled.div`
  display: flex;
  align-items: center;
  transition: ${theme.transition};
  cursor: pointer;
  &:hover {
    ${AuthText} {
      color: ${colors.yellow};
    }
    ${AvatarContainer} {
      background-color: ${colors.yellow};
    }
  }
`;

class Header extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    signIn: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
  };

  render() {
    const { currentUser, isSignedIn, signIn, signOut } = this.props;

    return (
      <HeaderContainer>
        <Nav>
          <NavText>Lunchstatement</NavText>
          <AuthButton onClick={isSignedIn ? signOut : signIn}>
            <AuthText>{isSignedIn ? 'Logout' : 'Login'}</AuthText>
            <AvatarContainer>
              {isSignedIn ? (
                <CurrentUserAvatar src={currentUser.photoURL} />
              ) : (
                <i className="fas fa-user" />
              )}
            </AvatarContainer>
          </AuthButton>
        </Nav>
      </HeaderContainer>
    );
  }
}

export default Header;

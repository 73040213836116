const google = window.google;
const upstatementLocation = new google.maps.LatLng(42.3638869, -71.0631657);
const oneMile = 1609.34;

const bounds = new google.maps.Circle({
  center: upstatementLocation,
  radius: oneMile,
}).getBounds();

const autocompleteService = new google.maps.places.AutocompleteService();

export const queryPlaces = (text, cb) => {
  autocompleteService.getPlacePredictions(
    {
      bounds: bounds,
      input: text,
      types: ['establishment'],
    },
    cb,
  );
};

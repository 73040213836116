import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../actions/commentActions';
import { objectToList } from '../lib/helpers';

import Comment from '../components/Comment';
import SubmitButton from '../components/SubmitButton';

import styled from 'styled-components/macro';
import { theme, Input, Ul } from '../style';
const { colors, fontSizes, spacing } = theme;

const CommentsHeading = styled.div`
  margin: ${spacing.lg} 0;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  font-size: ${fontSizes.xs};
  font-weight: 600;
  letter-spacing: 1px;
`;
const CommentsContainer = styled(Ul)``;
const CommentForm = styled.form`
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  position: relative;
`;
const CommentInput = styled(Input)`
  border-color: transparent;
  border-bottom-color: transparent;
  letter-spacing: 0;
  transition: ${theme.transition};
  padding-right: ${spacing.lg};

  &::placeholder {
    letter-spacing: 0;
  }
`;

class CommentList extends Component {
  static propTypes = {
    placeIndex: PropTypes.string.isRequired,
    currentUser: PropTypes.object.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    comments: PropTypes.array.isRequired,
    createFirebaseComment: PropTypes.func.isRequired,
    removeFirebaseComment: PropTypes.func.isRequired,
  };

  state = {
    inputActive: false,
  };

  componentDidMount() {}

  clearInput = e => {
    if (e.currentTarget.id !== 'comment') {
      e.target.value = '';
    }
  };

  handleChange = evt => {
    const val = evt.target.value;
    val ? this.setState({ inputActive: true }) : this.setState({ inputActive: false });
  };

  createComment = evt => {
    evt.preventDefault();
    const { placeIndex, currentUser, createFirebaseComment } = this.props;
    const comment = new FormData(evt.target).get('comment');
    const date = new Date().toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    if (comment) {
      createFirebaseComment(placeIndex, comment, currentUser.id, date);
      evt.target.reset();
    }
  };

  deleteComment = index => {
    const { placeIndex, removeFirebaseComment } = this.props;

    if (window.confirm('Do you really want to delete this comment?')) {
      removeFirebaseComment(placeIndex, index);
    }
  };

  handleCommentsHeading = comments => {
    const placeComments = objectToList(comments[0]);

    if (placeComments.length > 0) {
      const actualLength = placeComments.length - 1;
      return (
        <CommentsHeading>
          {actualLength} {actualLength < 2 ? `comment` : `comments`}
        </CommentsHeading>
      );
    }
  };

  render() {
    const { inputActive } = this.state;
    const { isSignedIn, comments } = this.props;
    const placeComments = objectToList(comments[0]);

    return (
      <div>
        {comments && this.handleCommentsHeading(comments)}

        <CommentsContainer>
          {placeComments &&
            placeComments.map((comment, index) => {
              return (
                comment.userId && (
                  <Comment
                    key={index}
                    commentIndex={comment.index}
                    comment={comment.comment}
                    date={comment.date}
                    onClick={this.deleteComment}
                    userId={comment.userId}
                    isSignedIn={isSignedIn}
                  />
                )
              );
            })}
        </CommentsContainer>

        {isSignedIn && (
          <CommentForm onSubmit={this.createComment}>
            <CommentInput
              id="comment"
              name="comment"
              type="text"
              placeholder="Leave a comment..."
              onBlur={this.clearInput}
              onChange={this.handleChange}
            />
            <SubmitButton inputActive={inputActive} />
          </CommentForm>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
  isSignedIn: user.isSignedIn,
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommentList);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';

import styled from 'styled-components/macro';
import { theme, Button } from '../style';
const { colors, fontSizes, spacing } = theme;

const ButtonContainer = styled(Button)`
  background-color: transparent;
  font-size: ${fontSizes.base};
  color: ${props => (props.isFavorite ? colors.red : 'inherit')};
  transition: ${theme.transition};
  &:hover {
    color: ${colors.red};
  }
`;
const Num = styled.span`
  margin-right: ${spacing.base};
`;

class FavoriteButton extends Component {
  static propTypes = {
    favoritePlace: PropTypes.func.isRequired,
    unfavoritePlace: PropTypes.func.isRequired,
    place: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    numFavorites: PropTypes.string.isRequired,
  };

  state = { isFavorite: false };

  componentDidMount() {
    const { place, currentUser } = this.props;

    this.checkIsFavorite(place, currentUser.id);
  }

  checkIsFavorite = (place, currentUserGId) => {
    const isInUserFavorites = some(place.favorites, { userGId: currentUserGId });

    this.setState({ isFavorite: isInUserFavorites });
  };

  toggleFavorite = () => {
    const { favoritePlace, unfavoritePlace, place } = this.props;
    const { isFavorite } = this.state;

    isFavorite ? unfavoritePlace(place.index) : favoritePlace(place.index);

    this.setState({ isFavorite: !isFavorite });
  };

  render() {
    const { isFavorite } = this.state;
    const { numFavorites } = this.props;

    return (
      <ButtonContainer onClick={this.toggleFavorite} isFavorite={isFavorite}>
        <Num>{numFavorites}</Num>
        {isFavorite ? <i className="fas fa-heart" /> : <i className="far fa-heart" />}
      </ButtonContainer>
    );
  }
}

export default FavoriteButton;

import * as types from '../actions/types';
import { SortTypes, SortOrder } from '../actions/placeActions';

const initialState = {
  places: [],
  sortType: SortTypes.DISTANCE,
  sortOrder: SortOrder.ASC,
};

const placeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PLACES:
      return {
        ...state,
        places: action.payload.places,
        sortType: action.payload.sortType,
        sortOrder: action.payload.sortOrder,
      };

    case types.CREATE_PLACE:
      return state;

    case types.REMOVE_PLACE:
      return state;

    case types.FAVORITE_PLACE:
      return state;

    case types.UNFAVORITE_PLACE:
      return state;

    default:
      return state;
  }
};

export default placeReducer;

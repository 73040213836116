/* @flow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions/userActions';
import { GoogleUser } from '../types';

import Header from '../components/Header';
import HtmlHead from '../components/HtmlHead';
import PlaceList from './PlaceList';

import styled from 'styled-components/macro';
import { GlobalStyle } from '../style';

const AppContainer = styled.div`
  min-height: 100vh;
`;

type AppProps = {
  currentUser: GoogleUser,
  isSignedIn: boolean,
  googleAuthSignIn: () => void,
  googleAuthSignOut: () => void,
  googleAuthChanged: () => void,
};

class App extends Component<AppProps> {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    googleAuthSignIn: PropTypes.func.isRequired,
    googleAuthSignOut: PropTypes.func.isRequired,
    googleAuthChanged: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.googleAuthChanged();
  }

  render() {
    const { currentUser, isSignedIn, googleAuthSignIn, googleAuthSignOut } = this.props;

    return (
      <AppContainer>
        <HtmlHead />
        <GlobalStyle />
        <Header
          currentUser={currentUser}
          isSignedIn={isSignedIn}
          signIn={googleAuthSignIn}
          signOut={googleAuthSignOut}
        />
        <PlaceList />
      </AppContainer>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  isSignedIn: state.user.isSignedIn,
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

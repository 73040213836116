import firebase from 'firebase/app';
import { auth } from './firebase';

const googleProvider = new firebase.auth.GoogleAuthProvider();

googleProvider.setCustomParameters({
  hd: 'upstatement.com',
});

export const googleAuth = () => auth.signInWithPopup(googleProvider);

export const authSignOut = () => auth.signOut();

export const firebaseAuth = auth;

import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

import { theme, media, Button } from '../style';
const { colors, fontSizes, spacing } = theme;

const ButtonContainer = styled(Button)`
  opacity: 0;
  background-color: transparent;
  font-size: ${fontSizes.sm};
  color: inherit;
  transition: ${theme.transition};
  margin-right: ${spacing.md};
  ${media.mobileL`opacity: 1;`};
  &:hover {
    color: ${colors.yellow};
  }
`;

const EditButton = ({ onClick, itemIndex, item }) => (
  <ButtonContainer onClick={() => onClick(itemIndex)} title={item ? `Edit ${item}` : 'Edit'}>
    <i className="fas fa-pencil-alt" />
  </ButtonContainer>
);

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  itemIndex: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
};

export default EditButton;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SubmitButton from './SubmitButton';

import styled from 'styled-components/macro';
import { theme, Input } from '../style';
const { colors, spacing } = theme;

const FormContainer = styled.form`
  background-color: ${props => (props.inputActive ? colors.white : colors.green)};
  color: ${props => (props.inputActive ? colors.green : colors.white)};
  border-top: ${props => (props.inputActive ? `1px solid ${colors.green}` : 0)};
  padding: 0 ${spacing.md} 0 ${spacing.lg};
  max-width: ${theme.cardWidth};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  z-index: 10;
  i {
    color: ${props => (props.inputActive ? colors.yellow : colors.white)};
  }
`;
const AddPlaceInput = styled(Input)`
  width: 100%;
  max-width: ${theme.cardWidth};
  padding-left: ${spacing.md};
  border: 0;
  background-color: transparent;
  color: ${props => (props.inputActive ? colors.green : colors.white)};
  &::placeholder {
    color: ${props => (props.inputActive ? colors.green : colors.white)};
    font-weight: 600;
    text-transform: uppercase;
  }
`;

class AddPlaceForm extends Component {
  static propTypes = {
    handleFocus: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    clearInput: PropTypes.func.isRequired,
    inputActive: PropTypes.bool.isRequired,
  };

  render() {
    const { handleFocus, handleChange, clearInput, inputActive } = this.props;

    return (
      <FormContainer inputActive={inputActive} onSubmit={evt => evt.preventDefault()}>
        <i className="fas fa-plus" />
        <AddPlaceInput
          type="text"
          placeholder="Add a Place"
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={clearInput}
          inputActive={inputActive}
        />
        <SubmitButton inputActive={inputActive} />
      </FormContainer>
    );
  }
}

export default AddPlaceForm;

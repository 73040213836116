import * as types from './types';
import { db } from '../firebase';
import { orderBy } from 'lodash';
import { objectToList } from '../lib/helpers';

const setPlaces = (places, sortType, sortOrder) => ({
  type: types.SET_PLACES,
  payload: {
    places,
    sortType,
    sortOrder,
  },
});

const createPlace = () => ({
  type: types.CREATE_PLACE,
  payload: {},
});

const removePlace = () => ({
  type: types.REMOVE_PLACE,
  payload: {},
});

const favoritePlace = () => ({
  type: types.FAVORITE_PLACE,
  payload: {},
});

const unfavoritePlace = () => ({
  type: types.UNFAVORITE_PLACE,
  payload: {},
});

export const SortTypes = {
  FAVORITES: 'favorites',
  DISTANCE: 'distance',
};

export const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
};

const sortPlaces = (places, sortType, sortOrder) => {
  switch (sortType) {
    case SortTypes.FAVORITES:
      return orderBy(places, place => (place.favorites ? Object.keys(place.favorites).length : 0), [
        sortOrder,
      ]);
    case SortTypes.DISTANCE:
      return orderBy(places, place => parseInt(new RegExp(/\d*/).exec(place.distance), 10), [
        sortOrder,
      ]);
    default:
      return places;
  }
};

export const setFirebasePlaces = (sortType, sortOrder) => {
  return dispatch => {
    db.onGetPlaces(snapshot => {
      const placeList = objectToList(snapshot.val());
      const sortedPlaces = sortPlaces(placeList, sortType, sortOrder);
      dispatch(setPlaces(sortedPlaces, sortType, sortOrder));
    });
  };
};

export const createFirebasePlace = (placeId, placeName, placeDistance) => {
  return dispatch => {
    db.doCreatePlace(placeId, placeName, placeDistance).then(() => {
      dispatch(createPlace());
    });
  };
};

export const removeFirebasePlace = id => {
  return dispatch => {
    db.doDeletePlace(id).then(() => {
      dispatch(removePlace());
    });
  };
};

export const favoriteFirebasePlace = (placeId, currentUserGId) => {
  return dispatch => {
    db.doCreateFavorite(placeId, currentUserGId).then(() => {
      dispatch(favoritePlace());
    });
  };
};

export const unfavoriteFirebasePlace = (placeId, currentUserGId) => {
  return dispatch => {
    db.doDeleteFavorite(placeId, currentUserGId).then(() => {
      dispatch(unfavoritePlace());
    });
  };
};

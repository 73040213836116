import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import DeleteButton from './DeleteButton';
import FavoriteButton from './FavoriteButton';

import styled from 'styled-components/macro';
import { theme, mixins, media, A } from '../style';
const { colors, fontSizes, spacing, fonts } = theme;

const TopWrapper = styled.div`
  ${mixins.flexBetween};
  margin: 0 0 ${spacing.lg};
`;
const Distance = styled.span`
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
  }
`;
const GMapsLink = styled(A)`
  &:hover {
    text-decoration: underline;
  }
`;
const NumFavesContainer = styled.span`
  color: ${colors.red};
`;
const NumFaves = styled.span`
  margin-right: ${spacing.base};
`;
const NameWrapper = styled.div`
  ${mixins.flexBetween};
  &:hover {
    button {
      opacity: 1;
    }
  }
`;
const PlaceName = styled.h2`
  color: ${colors.yellow};
  font-size: ${fontSizes.xl};
  line-height: 1.2;
  font-family: ${fonts.serif};
  font-weight: 700;
  margin: 0;
  ${media.mobileL`
    font-size: ${fontSizes.lg};
    padding-right: ${spacing.md};
  `};
`;

const PlaceHeader = ({
  currentUser,
  isSignedIn,
  place,
  removePlace,
  favoritePlace,
  unfavoritePlace,
}) => {
  const numFavorites =
    values(place.favorites).length > 0 ? `${values(place.favorites).length}` : '';

  return (
    <header>
      <TopWrapper>
        <Distance>
          {place.distance && (
            <GMapsLink
              href={`https://www.google.com/maps/search/?api=1&query=placeholder&query_place_id=${
                place.placeId
              }`}
              target="_blank"
              rel="noopener noreferrer">
              <i className="fas fa-walking" />
              <span>{place.distance}</span>
            </GMapsLink>
          )}
        </Distance>
        {isSignedIn ? (
          <FavoriteButton
            favoritePlace={favoritePlace}
            unfavoritePlace={unfavoritePlace}
            placeIndex={place.index}
            place={place}
            currentUser={currentUser}
            numFavorites={numFavorites}
          />
        ) : (
          place.favorites && (
            <NumFavesContainer>
              <NumFaves>{numFavorites}</NumFaves>
              <i className="fas fa-heart" />
            </NumFavesContainer>
          )
        )}
      </TopWrapper>
      <NameWrapper>
        <PlaceName>{place.name}</PlaceName>
        {isSignedIn && (
          <DeleteButton onClick={removePlace} itemIndex={place.index} item={place.name} />
        )}
      </NameWrapper>
    </header>
  );
};

PlaceHeader.propTypes = {
  currentUser: PropTypes.object.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  place: PropTypes.object.isRequired,
  removePlace: PropTypes.func.isRequired,
  favoritePlace: PropTypes.func.isRequired,
  unfavoritePlace: PropTypes.func.isRequired,
};

export default PlaceHeader;

import * as types from './types';
import { db } from '../firebase';
import { objectToList } from '../lib/helpers';

const setComments = comments => ({
  type: types.SET_COMMENTS,
  payload: comments,
});

const createComment = comments => ({
  type: types.CREATE_COMMENT,
  payload: comments,
});

const removeComment = comments => ({
  type: types.REMOVE_COMMENT,
  payload: comments,
});

export const setAllComments = () => {
  return dispatch => {
    db.onGetComments(snapshot => {
      const comments = objectToList(snapshot.val());
      dispatch(setComments(comments));
    });
  };
};

export const setPlaceComments = placeIndex => {
  return dispatch => {
    db.onGetPlaceComments(placeIndex, snapshot => {
      const comments = objectToList(snapshot.val());
      dispatch(setComments(comments));
    });
  };
};

export const createFirebaseComment = (placeIndex, comment, userId, date) => {
  return dispatch => {
    db.doCreatePlaceComment(placeIndex, comment, userId, date).then(() => {
      db.onGetPlaceComments(placeIndex, snapshot => {
        const comments = objectToList(snapshot.val());
        dispatch(createComment(comments));
      });
    });
  };
};

export const removeFirebaseComment = (placeIndex, index) => {
  return dispatch => {
    db.doDeletePlaceComment(placeIndex, index).then(() => {
      db.onGetPlaceComments(placeIndex, snapshot => {
        const comments = objectToList(snapshot.val());
        dispatch(removeComment(comments));
      });
    });
  };
};

// USER TYPES
export const USER_SIGN_IN = 'USER_SIGN_IN';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';

// PLACE TYPES
export const SET_PLACES = 'SET_PLACES';
export const SORT_PLACES = 'SORT_PLACES';
export const CREATE_PLACE = 'CREATE_PLACE';
export const REMOVE_PLACE = 'REMOVE_PLACE';
export const FAVORITE_PLACE = 'FAVORITE_PLACE';
export const UNFAVORITE_PLACE = 'UNFAVORITE_PLACE';

// COMMENT TYPES
export const SET_COMMENTS = 'SET_COMMENTS';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';

// ITEM TYPES
export const SET_ITEMS = 'SET_ITEMS';
export const CREATE_ITEM = 'CREATE_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { db } from '../firebase';
import { objectToList } from '../lib/helpers';

import DeleteButton from './DeleteButton';

import styled from 'styled-components/macro';
import { theme, Img } from '../style';
const { spacing, fontSizes } = theme;

const CommentContainer = styled.div`
  margin: ${spacing.md} 0 ${spacing.lg};
  transition: ${theme.transition};
  &:hover {
    .delete-btn {
      opacity: 1;
    }
  }
`;
const CommentAuthor = styled.div`
  display: flex;
  align-items: flex-start;
`;
const AuthorImg = styled(Img)`
  max-width: ${theme.userImgSize};
  overflow: hidden;
  border-radius: 50%;
`;
const AuthorDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 10px;
`;
const AuthorName = styled.div`
  font-weight: 700;
  margin: 0 0 5px;
`;
const CommentDate = styled.div`
  font-size: ${fontSizes.xs};
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
const CommentContent = styled.div`
  display: flex;
  padding-left: 40px;
`;
const CommentText = styled.div`
  flex: 1;
  line-height: 1.3rem;
  font-size: ${spacing.md};
`;

class Comment extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    date: PropTypes.string,
    commentIndex: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
  };

  state = {
    user: {},
  };

  componentDidMount() {
    db.onGetCurrentUser(this.props.userId, snapshot => {
      this.setState({ user: objectToList(snapshot.val())[0] });
    });
  }

  render() {
    const { user } = this.state;
    const { comment, date, commentIndex, onClick, isSignedIn } = this.props;

    return (
      <CommentContainer>
        <CommentAuthor>
          <AuthorImg src={user && user.photoURL} />
          <AuthorDetails>
            <AuthorName>{user && user.displayName}</AuthorName>
            {date && <CommentDate>{date}</CommentDate>}
          </AuthorDetails>
          {isSignedIn && <DeleteButton onClick={onClick} itemIndex={commentIndex} item="comment" />}
        </CommentAuthor>

        <CommentContent>
          <CommentText>{comment}</CommentText>
        </CommentContent>
      </CommentContainer>
    );
  }
}

export default Comment;

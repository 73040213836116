const theme = {
  // Colors
  colors: {
    green: `#7d9a81`,
    yellow: `#f5b581`,
    red: `#e77280`,
    eggshell: `#fafaf7`,
    grey: `#96a1ad`,
    lightGrey: `#ededed`,
    white: `#ffffff`,
    black: `#222222`,
  },

  // Fonts
  fonts: {
    serif: `Morion, serif`,
    sans: `Relative, sans-serif`,
    mono: `Lucida Console, Courier New, monospace`,
  },

  // Font sizes
  fontSizes: {
    base: `1rem`,
    xs: `0.7rem`,
    sm: `0.9rem`,
    md: `1.25rem`,
    lg: `2rem`,
    xl: `3rem`,
  },

  // Spacing
  spacing: {
    base: `0.5rem`,
    xs: `0.4rem`,
    sm: `0.75rem`,
    md: `1rem`,
    lg: `2rem`,
    xl: `3rem`,
  },

  transition: `all 0.3s cubic-bezier(0.19, 1, 0.22, 1)`,

  cardWidth: `500px`,

  arrowSize: `5px`,

  headerHeight: `80px`,

  toggleSize: `30px`,

  userImgSize: `30px`,
};

export default theme;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';
import { theme } from '../style';
const { colors, fontSizes } = theme;

const SubmitButtonInput = styled.input`
  background-color: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto;
  border: 0;
  color: inherit;
  font-size: ${fontSizes.base};
  cursor: pointer;

  &:hover {
    color: ${colors.yellow};
  }
`;

const SubmitButton = ({ inputActive }) => (
  <SubmitButtonInput name="submit" type="submit" value={inputActive ? '↵' : ''} />
);

SubmitButton.propTypes = {
  inputActive: PropTypes.bool.isRequired,
};

export default SubmitButton;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import SubmitButton from './SubmitButton';

import styled from 'styled-components/macro';
import { theme, media, Input, Button } from '../style';
const { colors, fontSizes, spacing, fonts } = theme;

const ListItem = styled.li`
  display: flex;
  align-items: baseline;
  counter-increment: item;
  font-family: ${fonts.serif};
  font-size: ${fontSizes.md};
  font-weight: 700;
  position: relative;
  text-transform: capitalize;
  transition: ${theme.transition};
  margin-bottom: ${spacing.lg};
  &:before {
    content: counter(item) '.';
    font-family: ${fonts.sans};
    font-size: ${fontSizes.sm};
    font-weight: 400;
    text-align: right;
    width: 30px;
    padding-left: ${spacing.sm};
    margin-right: ${spacing.md};
  }
  &:hover {
    button {
      opacity: 1;
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const ItemDescription = styled.span`
  display: flex;
  justify-content: space-between;
  max-width: 85%;
  width: 100%;
`;
const ItemText = styled.span`
  flex-grow: 1;
  width: calc(100% - 50px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ButtonsToggle = styled(Button)`
  background-color: transparent;
  opacity: 0.8;
  transition: ${theme.transition};
  margin-left: ${spacing.md};
  display: none;
  ${media.mobileL`
    display: block;
  `};
`;
const ItemButtons = styled.span`
  white-space: nowrap;
  margin-left: 10px;
  transition: ${theme.transition};
  transition-duration: 0.5s;
  opacity: ${props => (props.visible ? `1` : `0`)};
`;
const EditForm = styled.form`
  width: 100%;
  position: relative;
`;
const EditInput = styled(Input)`
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid ${colors.green};
  padding: 5px 0;
  font-family: ${fonts.serif};
  font-size: ${fontSizes.md};
  font-weight: 700;
  &::placeholder {
    text-transform: uppercase;
  }
`;

class Item extends Component {
  static propTypes = {
    isSignedIn: PropTypes.bool.isRequired,
    item: PropTypes.string.isRequired,
    itemIndex: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    editItem: PropTypes.func.isRequired,
  };

  state = {
    isEditable: false,
    buttonsVisible: true,
  };

  componentDidMount() {
    if (window.innerWidth < 520) {
      this.setState({ buttonsVisible: false });
    }
  }

  toggleEdit = () => {
    this.setState({ isEditable: !this.state.isEditable });
  };

  updateItem = evt => {
    const { itemIndex, editItem } = this.props;
    editItem(evt, itemIndex);
    this.setState({ isEditable: false });
  };

  toggleButtons = () => {
    this.setState({ buttonsVisible: !this.state.buttonsVisible });
  };

  render() {
    const { isEditable, buttonsVisible } = this.state;
    const { isSignedIn, item, itemIndex, onDelete } = this.props;

    return (
      <ListItem>
        {isEditable ? (
          <EditForm onSubmit={evt => this.updateItem(evt)}>
            <EditInput
              type="text"
              name="name"
              defaultValue={item}
              placeholder="Item name"
              onBlur={this.toggleEdit}
            />
            <SubmitButton inputActive={isEditable} />
          </EditForm>
        ) : (
          <ItemDescription>
            <ItemText>{item}</ItemText>

            <ItemButtons visible={buttonsVisible}>
              {isSignedIn && (
                <EditButton onClick={this.toggleEdit} itemIndex={itemIndex} item={item} />
              )}
              {isSignedIn && <DeleteButton onClick={onDelete} itemIndex={itemIndex} item={item} />}
            </ItemButtons>

            <ButtonsToggle onClick={this.toggleButtons} visible={buttonsVisible}>
              {buttonsVisible ? (
                <i className="fas fa-times" />
              ) : (
                <i className="fas fa-ellipsis-h" />
              )}
            </ButtonsToggle>
          </ItemDescription>
        )}
      </ListItem>
    );
  }
}

export default Item;

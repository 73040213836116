import styled from 'styled-components/macro';
import theme from './theme';
const { colors, fontSizes, spacing, fonts } = theme;

const Input = styled.input`
  border-radius: 0;
  border: 1px solid ${colors.green};
  outline: 0;
  padding: ${spacing.md};
  width: 100%;
  color: ${colors.green};
  font-size: ${fontSizes.base};
  font-family: ${fonts.sans};
  &:focus,
  &:active {
    outline: 0;
    &::placeholder {
      opacity: 0.5;
    }
  }
  &::placeholder {
    color: ${colors.green};
    letter-spacing: 1px;
    font-size: ${fontSizes.sm};
  }
`;

export default Input;

import styled from 'styled-components/macro';
import theme from './theme';
const { fontSizes } = theme;

const Button = styled.button`
  border: 0;
  padding: 0;
  font-size: ${fontSizes.base};
  transition: ${theme.transition};
  color: inherit;
  cursor: pointer;
  &:focus,
  &:active {
    outline: 0;
  }
`;

export default Button;

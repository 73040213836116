import { db } from './firebase';

// Create
export const doCreatePlace = (placeId, name, distance) => {
  return db.ref('places/').push({ placeId, name, distance });
};

export const doCreateUser = (gId, displayName, photoURL) => {
  return db.ref(`users/${gId}`).push({ displayName, photoURL });
};

export const doCreatePlaceComment = (place, comment, userId, date) => {
  if (!db.ref(`comments/${place}`).once('value')) {
    return db
      .ref('comments/')
      .push(place)
      .push({ comment, userId, date });
  } else {
    return db.ref(`comments/${place}/`).push({ comment, userId, date });
  }
};

export const doCreatePlaceItem = (place, item) => {
  if (!db.ref(`items/${place}`).once('value')) {
    return db
      .ref('items/')
      .push(place)
      .push({ item });
  } else {
    return db.ref(`items/${place}`).push({ item });
  }
};

export const doCreateFavorite = (placeId, userGId) => {
  return Promise.all([
    db.ref(`favorites/${userGId}/places/${placeId}`).set({ placeId }),
    db.ref(`places/${placeId}/favorites/${userGId}`).set({ userGId }),
  ]);
};

// Read
export const onGetPlaces = cb => db.ref('places').on('value', cb);
export const onGetComments = cb => db.ref(`comments`).on('value', cb);
export const onGetItems = cb => db.ref(`items`).on('value', cb);

export const onGetPlaceComments = (place, cb) => db.ref(`comments/${place}`).on('value', cb);
export const onGetPlaceItems = (place, cb) => db.ref(`items/${place}`).on('value', cb);

export const onGetUsers = cb => db.ref(`users`).on('value', cb);
export const onGetCurrentUser = (gId, cb) => db.ref(`users/${gId}`).on('value', cb);

// Update
export const doUpdateItem = (place, itemIndex, newItemValue, cb) =>
  db.ref(`items/${place}/${itemIndex}`).update({ item: newItemValue }, cb);

// Delete
export const doDeletePlace = id => {
  return Promise.all([
    db.ref(`places/${id}`).remove(),
    db.ref(`items/${id}`).remove(),
    db.ref(`comments/${id}`).remove(),
  ]);
};
export const doDeletePlaceComment = (place, ref) => db.ref(`comments/${place}/${ref}`).remove();
export const doDeletePlaceItem = (place, ref) => db.ref(`items/${place}/${ref}`).remove();

export const doDeletePlaceFavorite = (placeId, userGId) => {
  return db.ref(`places/${placeId}/favorites/${userGId}`).remove();
};

export const doDeleteFavoritesUser = (placeId, userGId) => {
  return db.ref(`favorites/${userGId}`).push({ userGId });
};

export const doDeleteFavorite = (placeId, userGId) => {
  return Promise.all([
    db.ref(`places/${placeId}/favorites/${userGId}`).remove(),
    db.ref(`favorites/${userGId}/places/${placeId}`).remove(),
  ]);
};

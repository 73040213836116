const google = window.google;

const upstatementPlaceId = {
  placeId: 'ChIJo_TYcIB644kR-SiA1brCdrk',
};
const directionsService = new google.maps.DirectionsService();

export const getPlaceDistance = (placeId, cb) => {
  directionsService.route(
    {
      origin: upstatementPlaceId,
      destination: { placeId },
      travelMode: 'WALKING',
    },
    cb,
  );
};
